import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Flex, Button, Container, useDisclosure, Text } from "@chakra-ui/react";
import { datesAreOnSameDay, displayDate } from "../../utils/utils";
import GameWrapper from "../../components/GameWrapper";
import LearnModal from "../../components/LearnModal";
import InvitationModal from "../../components/InvitationModal";
import TutorialModal from "../../components/TutorialModal";
import AboutModal from "../../components/AboutDrawer";
import RareWordsDrawer from "../../components/RareWordsDrawer";
import PlayerStatsDrawer from "../../components/PlayerStats";
import OptionsModal from "../../components/OptionsModal";
import GameArchiveDrawer from "../../components/GameArchiveDrawer";
import GamePageNav from "./GamePageNav";
import CraftWordLogoLong from "../../components/CraftWordLogoLong";
import {
  checkIfLoggedIn,
  fetchAllFinalGameStates,
  fetchWordList,
  loadGameState,
  saveGameState,
} from "../../api/api";
import ReactGA from "react-ga4";
import { v4 as uuid } from "uuid";
import {
  type CustomEventOptions,
  type GameState,
  type GameDetailType,
} from "../../components/types";
import ContinueGameModal from "../../components/ContinueGameModal";
import AlertModal from "../../components/AlertModal";
import IOSAnnouncement from "../../components/IOSAnnouncement";
import NewFeatureModal from "../../components/NewFeatureModal";
import AuthModal from "../../components/AuthModal";
import AdBanner from "../../components/AdBanner";
import { LuLogIn } from "react-icons/lu";
import { useArchiveDate } from "../../ArchiveDateContext";

export default function App() {
  const currentDate = useMemo(() => new Date(), []);
  const { setArchiveDate } = useArchiveDate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [playedTodaysGame, setPlayedTodaysGame] = useState(false);
  const [gameDetails, setGameDetails] = useState<GameDetailType[] | undefined>(
    undefined,
  );
  const [wordList, setWordList] = useState<string[] | null>(null);
  const [firstPlay, setFirstPlay] = useState<boolean>(true);
  const [showGameSummary, setShowGameSummary] = useState(false);
  const [isContinueModalOpen, setIsContinueModalOpen] = useState(false);
  const [, setSumBestPossibleLength] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [useVirtualKeyBoard, setUseVirtualKeyboard] = useState(false);
  const [competitive, setCompetitive] = useState<boolean>(() => {
    const storedCompetitive = localStorage.getItem("competitive");
    return storedCompetitive ? storedCompetitive === "true" : true;
  });
  const [marginTop, setMarginTop] = useState("7vh");
  const [height, setHeight] = useState("93vh");
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const initialHeightRef = useRef(window.innerHeight);
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // If the window height drops more than 150px from the initial height,
      // we assume the mobile keyboard is visible.
      if (window.innerHeight < initialHeightRef.current - 150) {
        setKeyboardVisible(true);
      } else {
        setKeyboardVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const updateViewableArea = () => {
      const windowHeight = window.innerHeight;
      if (windowHeight > 800) {
        setMarginTop("7vh");
        setHeight("93vh");
        setIsSmallScreen(false);
      } else if (windowHeight > 675 && windowHeight <= 800) {
        setMarginTop("5vh");
        setHeight("95vh");
        setIsSmallScreen(false);
      } else {
        setMarginTop("3vh");
        setHeight("97vh");
        setIsSmallScreen(true);
      }
    };

    updateViewableArea();
    window.addEventListener("resize", updateViewableArea);
    const virtualKeyboardPreference =
      localStorage.getItem("useVirtualKeyboard");
    if (virtualKeyboardPreference !== null) {
      setUseVirtualKeyboard(virtualKeyboardPreference === "true");
    } else {
      if (isSmallScreen && isMobile) {
        console.log("Small screen on mobile detected.");
      }
    }

    return () => {
      window.removeEventListener("resize", updateViewableArea);
    };
  }, []);

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const closeInviteModal = () => setIsInviteModalOpen(false);
  const goToSite = () => {
    if (window.top) {
      window.top.location.href = "https://craftword.game/";
    } else {
      console.error("Unable to redirect: window.top is null.");
    }
  };

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgentMobileCheck =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        );
      const touchCheck =
        navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
      const platformCheck = /iPad|iPhone|iPod/.test(navigator.platform);
      return userAgentMobileCheck || touchCheck || platformCheck;
    };

    setIsMobile(checkIfMobile());
  }, []);

  const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send("pageview");
  } else {
    console.error("GA_MEASUREMENT_ID is not defined");
  }
  const trackEvent = (eventOptions: CustomEventOptions) => {
    ReactGA.event(eventOptions);
  };

  const [gameState, setGameState] = useState<GameState>({
    rounds: [],
    currentRoundIndex: 0,
    date: undefined,
    completed: false,
  });

  const [userId, setUserId] = useState<string | null>(null);
  useEffect(() => {
    let localUserId = localStorage.getItem("userId");
    if (!localUserId) {
      localUserId = uuid();
      localStorage.setItem("userId", localUserId);
    }
    setUserId(localUserId);
  }, []);

  useEffect(() => {
    if (!userId) return;
    const fetchGameState = async () => {
      const fetchedGameState = await loadGameState(userId);
      setGameState(
        fetchedGameState || {
          rounds: [],
          currentRoundIndex: 0,
          date: undefined,
          completed: false,
        },
      );
    };

    fetchGameState();
  }, [userId]);

  const [loggedIn, setLoggedIn] = useState(false);
  const [emailLogin, setEmailLogin] = useState("");

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const result = await checkIfLoggedIn();
        setLoggedIn(result.loggedIn);
        if (result.email) {
          setEmailLogin(result.email);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };

    checkLoginStatus();
  }, [userId, loggedIn]);

  const [totalScore, setTotalScore] = useState(0);
  const [, setTotalTime] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isGameArchiveDrawerOpen, setIsGameArchiveDrawerOpen] = useState(false);

  useEffect(() => {
    const initializeGameDetails = async () => {
      if (!userId) return;
      const fetchedData = await fetchAllFinalGameStates(userId);
      if (fetchedData && fetchedData.allGames) {
        setGameDetails(fetchedData.allGames);
      } else {
        console.error("Could not load game states from server");
        setGameDetails([]);
      }
    };

    initializeGameDetails();
  }, [userId, isGameOver, isGameArchiveDrawerOpen]);

  let resetCurrentRoundIndex = () => {};
  const resetRounds = () => {};
  const [finalActiveTime, setFinalActiveTime] = useState<number | null>(() => {
    const savedFinalActiveTime = localStorage.getItem("finalActiveTime");
    return savedFinalActiveTime !== null
      ? parseInt(savedFinalActiveTime, 10)
      : null;
  });
  const {
    isOpen: isLearnModalOpen,
    onOpen: onLearnModalOpen,
    onClose: onLearnModalClose,
  } = useDisclosure();

  const {
    isOpen: isTutorialModalOpen,
    onOpen: onTutorialModalOpen,
    onClose: onTutorialModalClose,
  } = useDisclosure();

  const {
    isOpen: isAboutModalOpen,
    onOpen: onAboutModalOpen,
    onClose: onAboutModalClose,
  } = useDisclosure();

  const {
    isOpen: isRareWordsDrawerOpen,
    onOpen: onRareWordsDrawerOpen,
    onClose: onRareWordsDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isPlayerStatsDrawerOpen,
    onOpen: onPlayerStatsDrawerOpen,
    onClose: onPlayerStatsDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isOptionsModalOpen,
    onOpen: onOptionsModalOpen,
    onClose: onOptionsModalClose,
  } = useDisclosure();

  const {
    isOpen: isAlertModalOpen,
    onOpen: onAlertModalOpen,
    onClose: onAlertModalClose,
  } = useDisclosure();

  const {
    isOpen: isIOSAnnouncementOpen,
    onOpen: onIOSAnnouncementOpen,
    onClose: onIOSAnnouncementClose,
  } = useDisclosure();

  const {
    isOpen: isAuthModalOpen,
    onOpen: onAuthModalOpen,
    onClose: onAuthModalClose,
  } = useDisclosure();

  const handleGameArchiveOpen = () => {
    setIsPlaying(false);
    setIsGameArchiveDrawerOpen(true);
    if (userId) {
      fetchAllFinalGameStates(userId).then((fetchedData) => {
        if (fetchedData && fetchedData.allGames) {
          setGameDetails(fetchedData.allGames);
        }
      });
    }
  };

  useEffect(() => {
    const firstPlayStored = localStorage.getItem("firstPlay");
    if (firstPlayStored === null) {
      localStorage.setItem("firstPlay", "true");
      setFirstPlay(true);
    } else {
      setFirstPlay(firstPlayStored === "true");
    }
  }, []);

  useEffect(() => {
    const loadWordList = async () => {
      try {
        const loadedWordList = await fetchWordList();
        if (loadedWordList) {
          setWordList(loadedWordList);
        } else {
          console.error("Could not load word list from server");
        }
      } catch (error) {
        console.error("Error loading word list:", error);
      }
    };
    loadWordList().catch((error) => {
      console.error("Error loading word list:", error);
    });
  }, []);

  const defaultGameState = {
    rounds: [],
    currentRoundIndex: 0,
    date: undefined,
    completed: false,
  };

  const handleStartButtonClick = () => {
    if (window.top && window.top !== window.self) {
      setIsInviteModalOpen(true);
    } else {
      if (firstPlay) {
        onTutorialModalOpen();
        startTutorial();
      } else {
        localStorage.removeItem("activeTime");
        localStorage.removeItem("finalActiveTime");
        setArchiveDate(currentDate);
        setIsPlaying(true);
      }
    }
  };

  const resetGame = useCallback(async () => {
    localStorage.removeItem("activeTime");
    localStorage.removeItem("finalActiveTime");
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        await saveGameState(
          userId,
          defaultGameState.rounds,
          defaultGameState.currentRoundIndex,
          defaultGameState.completed,
          defaultGameState.date,
        );
        setIsPlaying(false);
        setGameState(undefined);
        setArchiveDate(currentDate);
        setIsPlaying(false);
      } catch (error) {
        console.error("Failed to reset game state:", error);
      }
    } else {
      console.warn("UserId not found for resetting game state.");
    }
  }, [setIsPlaying, setGameState]);

  const startTutorial = () => {
    setIsPlaying(false);
    setFirstPlay(true);
    resetCurrentRoundIndex();
    setIsPlaying(true);
    setShowGameSummary(false);
  };

  const handleContinue = () => {
    setIsContinueModalOpen(false);
    setIsPlaying(true);
  };

  const handleStartNew = () => {
    resetGame();
    setArchiveDate(currentDate);
    setIsContinueModalOpen(false);
    setIsPlaying(false);
  };

  const onTutorialComplete = () => {
    setFirstPlay(false);
    localStorage.setItem("seenNewFeatureModal", "true");
    trackEvent({
      category: "Game",
      action: "Complete Tutorial",
      label: process.env.NODE_ENV,
    });
    resetCurrentRoundIndex();
    localStorage.setItem("firstPlay", "false");
    localStorage.removeItem("finalActiveTime");
    localStorage.removeItem("activeTime");
    setIsPlaying(true);
  };

  const gameLength = 3;

  useEffect(() => {
    if (!gameState) {
      setGameState({
        rounds: [],
        currentRoundIndex: 0,
        date: undefined,
        completed: false,
      });
      setIsPlaying(false);
      setArchiveDate(currentDate);
      return;
    }
    if (gameState.date) {
      const gameDate = new Date(gameState.date);
      if (datesAreOnSameDay(gameDate, currentDate)) {
        setFirstPlay(false);
        setArchiveDate(gameDate);
        setIsPlaying(true);
      } else {
        if (!gameState.completed) {
          setArchiveDate(gameState.date ? new Date(gameState.date) : undefined);
          setIsPlaying(false);
          setIsContinueModalOpen(true);
        } else {
          resetGame();
          setIsPlaying(false);
        }
      }
    }
  }, [gameState, currentDate, setArchiveDate]);

  const handleArchiveDateSelect = (date: Date | undefined) => {
    setArchiveDate(date);
    setIsGameOver(false);
    setFirstPlay(false);
    resetCurrentRoundIndex();
    setIsPlaying(true);
    setShowGameSummary(false);
  };

  const handleGameOver = useCallback(() => {
    setIsGameOver(true);
    if (userId) {
      fetchAllFinalGameStates(userId).then((fetchedData) => {
        if (fetchedData && fetchedData.allGames) {
          setGameDetails(fetchedData.allGames);
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    const disableBounce: EventListener = (e) => {
      e.preventDefault();
    };

    const scrollableElement = document.querySelector(".scrollable-container");

    if (scrollableElement) {
      scrollableElement.addEventListener("touchmove", disableBounce, {
        passive: false,
      });
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("touchmove", disableBounce);
      }
    };
  }, []);

  useEffect(() => {
    const msUntilMidnight = () => {
      const now = new Date();
      const midnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
      );
      return midnight.getTime() - now.getTime();
    };

    const timer = setTimeout(() => {
      window.location.reload();
    }, msUntilMidnight());

    return () => clearTimeout(timer);
  }, []);

  const isStandaloneMode = () => {
    if (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.matchMedia("(display-mode: fullscreen)").matches
    ) {
      return true;
    }
    if ((navigator as any).standalone) {
      return true;
    }
    if (window.matchMedia("(display-mode: minimal-ui)").matches) {
      return true;
    }
    if (
      document.referrer.startsWith("android-app://") ||
      document.referrer.startsWith("ios-app://")
    ) {
      return true;
    }
    return false;
  };

  const onShowAlertMessage = () => {
    setTimeout(() => {
      onAlertModalOpen();
    }, 3000);
  };

  useEffect(() => {
    const isDontShowAgainSet = localStorage.getItem("dontShowAlertModal");
    if (showGameSummary && !isDontShowAgainSet) {
      const isStandalone = isStandaloneMode();
      if (!loggedIn || (isMobile && !isStandalone)) {
        onShowAlertMessage();
      }
    }
  }, [showGameSummary, loggedIn, isMobile, onShowAlertMessage]);

  const [isLogoutMode, setIsLogoutMode] = useState(false);
  const [isNewFeatureModalOpen, setIsNewFeatureModalOpen] = useState(false);

  useEffect(() => {
    if (firstPlay === false) {
      const seenNewFeatureModal = localStorage.getItem("seenNewFeatureModal");
      if (!seenNewFeatureModal) {
        setIsNewFeatureModalOpen(true);
      }
    }
  }, [firstPlay]);

  // ----- IOSAnnouncement Logic -----
  const COOLDOWN_PERIOD = 60 * 60 * 1000; // 1 hour
  const [canShowIOSAnnouncement, setCanShowIOSAnnouncement] = useState(() => {
    const lastInteractionTime = localStorage.getItem(
      "IOSAnnouncementLastInteraction",
    );
    if (lastInteractionTime) {
      const timeSinceLastInteraction =
        Date.now() - parseInt(lastInteractionTime, 10);
      return timeSinceLastInteraction >= COOLDOWN_PERIOD;
    }
    return true;
  });

  const shouldShowIOSAnnouncement =
    (!loggedIn || (isMobile && !isStandaloneMode())) &&
    canShowIOSAnnouncement &&
    !localStorage.getItem("dontIOSAnnouncement");

  useEffect(() => {
    if (shouldShowIOSAnnouncement) {
      onIOSAnnouncementOpen();
    }
  }, [shouldShowIOSAnnouncement, onIOSAnnouncementOpen]);
  // ---------------------------------

  return (
    <Container
      centerContent
      overflowX="hidden"
      className="scrollable-container"
    >
      <GamePageNav
        onLearnModalOpen={onLearnModalOpen}
        onAboutModalOpen={onAboutModalOpen}
        onGameArchiveOpen={handleGameArchiveOpen}
        onOptionsModalOpen={onOptionsModalOpen}
        onRareWordsDrawerOpen={onRareWordsDrawerOpen}
        onPlayerStatsDrawerOpen={onPlayerStatsDrawerOpen}
        onAuthModalOpen={onAuthModalOpen}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        resetGame={resetGame}
        isSmallScreen={isSmallScreen}
        userId={userId}
        setUserId={setUserId}
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        emailLogin={emailLogin}
        setIsLogoutMode={setIsLogoutMode}
      />
      {keyboardVisible && <AdBanner />}
      <Flex mt={marginTop} height={height} w="390px">
        <Flex
          flex="1"
          h="100%"
          w="100%"
          direction="column"
          alignItems="center"
          justify="center"
          mx={4}
        >
          <Flex
            flex="1"
            w="100%"
            direction="column"
            align="center"
            justify="center"
          >
            {isPlaying && wordList ? (
              <GameWrapper
                wordList={wordList}
                onTutorialComplete={onTutorialComplete}
                firstPlay={firstPlay}
                gameLength={gameLength}
                gameState={gameState}
                playedTodaysGame={playedTodaysGame}
                setPlayedTodaysGame={setPlayedTodaysGame}
                handleGameArchiveOpen={handleGameArchiveOpen}
                setTotalScore={setTotalScore}
                totalScore={totalScore}
                setSumBestPossibleLength={setSumBestPossibleLength}
                setTotalTime={setTotalTime}
                onGameOver={handleGameOver}
                isGameOver={isGameOver}
                gameDetails={gameDetails}
                setResetCurrentRoundIndex={(resetFunction) => {
                  resetCurrentRoundIndex = resetFunction;
                }}
                finalActiveTime={finalActiveTime}
                setFinalActiveTime={setFinalActiveTime}
                showGameSummary={showGameSummary}
                setShowGameSummary={setShowGameSummary}
                resetRounds={resetRounds}
                userId={userId}
                isMobile={isMobile}
                useVirtualKeyBoard={useVirtualKeyBoard}
                marginTop={marginTop}
                isSmallScreen={isSmallScreen}
                isTutorialModalOpen={isTutorialModalOpen}
                onPlayerStatsDrawerOpen={onPlayerStatsDrawerOpen}
                onShowAlertMessage={onShowAlertMessage}
                competitive={competitive}
              />
            ) : (
              <>
                <Text color="grayBrand.500" mb="4">
                  {displayDate(currentDate)}
                </Text>
                <CraftWordLogoLong />
                <Text fontSize="xx-large" fontWeight="semibold" my="4">
                  Round 1/{gameLength}
                </Text>
                <Button
                  colorScheme="orangeBrand"
                  onClick={handleStartButtonClick}
                >
                  Start
                </Button>
                {loggedIn && (
                  <Flex
                    alignItems="center"
                    fontSize="xs"
                    mt="4"
                    color="grayBrand.500"
                    textTransform="uppercase"
                  >
                    <LuLogIn fontSize="sm" />
                    <Text ml="2">{emailLogin}</Text>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>

      <AboutModal isOpen={isAboutModalOpen} onClose={onAboutModalClose} />
      <RareWordsDrawer
        isOpen={isRareWordsDrawerOpen}
        onClose={onRareWordsDrawerClose}
        gameDetails={gameDetails}
      />
      <PlayerStatsDrawer
        isOpen={isPlayerStatsDrawerOpen}
        onClose={onPlayerStatsDrawerClose}
        gameDetails={gameDetails}
        onRareWordsDrawerOpen={onRareWordsDrawerOpen}
      />
      <GameArchiveDrawer
        isOpen={isGameArchiveDrawerOpen}
        onClose={() => {
          setIsGameArchiveDrawerOpen(false);
        }}
        onDateSelect={handleArchiveDateSelect}
        setIsPlaying={setIsPlaying}
        gameDetails={gameDetails}
      />
      <LearnModal
        isOpen={isLearnModalOpen}
        onClose={onLearnModalClose}
        firstPlay={firstPlay}
        startTutorial={startTutorial}
      />
      <TutorialModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        openLearnModal={onLearnModalOpen}
        firstPlay={firstPlay}
        onTutorialComplete={onTutorialComplete}
        startTutorial={startTutorial}
      />
      <AboutModal isOpen={isAboutModalOpen} onClose={onAboutModalClose} />
      <ContinueGameModal
        isOpen={isContinueModalOpen}
        onClose={() => setIsContinueModalOpen(false)}
        onContinue={handleContinue}
        onStartNew={handleStartNew}
      />
      <InvitationModal
        isOpen={isInviteModalOpen}
        onClose={closeInviteModal}
        onGoToSite={goToSite}
      />
      <OptionsModal
        isOpen={isOptionsModalOpen}
        onClose={onOptionsModalClose}
        setUseVirtualKeyboard={setUseVirtualKeyboard}
        useVirtualKeyboard={useVirtualKeyBoard}
        competitive={competitive}
        setCompetitive={setCompetitive}
      />
      {shouldShowIOSAnnouncement && (
        <IOSAnnouncement
          isOpen={isIOSAnnouncementOpen}
          onClose={onIOSAnnouncementClose}
          onAuthModalOpen={onAuthModalOpen}
          isMobile={isMobile}
          isStandalone={isStandaloneMode()}
          isLoggedIn={loggedIn}
        />
      )}
      {!shouldShowIOSAnnouncement && (
        <AlertModal
          isOpen={isAlertModalOpen}
          onClose={onAlertModalClose}
          onAuthModalOpen={onAuthModalOpen}
          isMobile={isMobile}
          isStandalone={isStandaloneMode()}
          isLoggedIn={loggedIn}
        />
      )}
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={onAuthModalClose}
        setUserId={setUserId}
        userId={userId}
        setLoggedIn={setLoggedIn}
        emailLogin={emailLogin}
        isLogoutMode={isLogoutMode}
        setIsLogoutMode={setIsLogoutMode}
      />
      <NewFeatureModal
        isOpen={isNewFeatureModalOpen}
        onClose={() => setIsNewFeatureModalOpen(false)}
      />
      {!keyboardVisible && <AdBanner />}
    </Container>
  );
}
