import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { fetchSolutionsData } from "./api/api"; // adjust your import as needed
import { RoundSolutionData } from "./components/types";
import { useArchiveDate } from "./ArchiveDateContext"; // import the hook

type SolutionsContextType = {
  solutionsData: RoundSolutionData[];
};

const SolutionsContext = createContext<SolutionsContextType | undefined>(
  undefined,
);

type SolutionsProviderProps = {
  children: ReactNode;
};

export const SolutionsProvider: React.FC<SolutionsProviderProps> = ({
  children,
}) => {
  const [solutionsData, setSolutionsData] = useState<RoundSolutionData[]>([]);
  const { archiveDate } = useArchiveDate();

  useEffect(() => {
    if (!archiveDate) {
      setSolutionsData([]);
      return;
    }

    const loadData = async () => {
      try {
        const data = await fetchSolutionsData(archiveDate);
        // If you need to filter out profanity like in StatsDrawer, do it here:
        // const filteredData = data.map(...);
        setSolutionsData(data);
      } catch (error) {
        console.error("Error fetching solutions:", error);
        setSolutionsData([]);
      }
    };

    loadData();
  }, [archiveDate]);

  return (
    <SolutionsContext.Provider value={{ solutionsData }}>
      {children}
    </SolutionsContext.Provider>
  );
};

export const useSolutions = (): SolutionsContextType => {
  const context = useContext(SolutionsContext);
  if (!context) {
    throw new Error("useSolutions must be used within a SolutionsProvider");
  }
  return context;
};

export default SolutionsProvider;
