import React, { useEffect, useRef } from "react";

const AdBanner = () => {
  // Use the correct ref type for the <ins> element
  const adRef = useRef<HTMLModElement>(null);

  useEffect(() => {
    if (adRef.current) {
      try {
        // Ensure the global adsbygoogle array is defined, then push a new ad
        (window as any).adsbygoogle = (window as any).adsbygoogle || [];
        (window as any).adsbygoogle.push({});
      } catch (error) {
        console.error("Adsense error:", error);
      }
    }
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "10px 0" }}>
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={{
          display: "block",
          width: "100%",
          minWidth: "300px", // Ensure a minimum width
          minHeight: "150px", // Optionally set a minimum height for debugging
        }}
        data-ad-client="ca-pub-1473843240366639"
        data-ad-slot="4120064639"
        data-ad-format="auto"
        data-full-width-responsive="true"
        data-adtest="on"
      ></ins>
    </div>
  );
};

export default AdBanner;
