import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import CraftWordLogoLong from "../components/CraftWordLogoLong";

const SupportPage = () => {
  const [message, setMessage] = useState("");

  const handleSendFeedback = () => {
    window.location.href = `mailto:craftwordfeedback@gmail.com?subject=CraftWord Support&body=${encodeURIComponent(
      message,
    )}`;
  };

  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={6} textAlign="center">
        <CraftWordLogoLong width="90%" />
        <Heading size="lg" color="orangeBrand.500">
          Support & Feedback
        </Heading>
        <Text fontSize="lg" color="grayBrand.700">
          Have feedback or need help? Send us a message below!
        </Text>
        <Textarea
          placeholder="Write your feedback or support request here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          size="lg"
          borderColor="grayBrand.300"
        />
        <Button variant="orangeBrand" size="lg" onClick={handleSendFeedback}>
          Send Feedback
        </Button>
      </VStack>
    </Container>
  );
};

export default SupportPage;
