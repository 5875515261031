import GameRoundResult from "./GameRoundResult";
import GaveUpRound from "./GaveUpRound";
import GameSummary from "./GameSummary/GameSummary";
import Round from "./Round";
import GameHeader from "./GameHeader";
import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { type RoundType, GameDetailType } from "./types";
import {
  calculateTotalScore,
  calculateTotalTime,
  formatDateToLocal,
} from "../utils/utils";
import { Spinner, Flex, keyframes } from "@chakra-ui/react";
import { useArchiveDate } from "../ArchiveDateContext";

type GameProps = {
  wordList: string[];
  gameLength: number;
  rounds: RoundType[];
  setRounds: (rounds: RoundType[]) => void;
  currentRoundIndex: number;
  setCurrentRoundIndex: (currentRoundIndex: number) => void;
  currentRound: RoundType;
  isRoundOver: boolean;
  isGameOver: boolean;
  addMove: (move: string, direction: "forward" | "backward" | "both") => void;
  advanceRound: () => void;
  winningAnimationOver: boolean;
  undoMove: () => void;
  undoBackwardsMove: () => void;
  clearMoves: () => void;
  playedTodaysGame: boolean;
  setPlayedTodaysGame: (playedTodaysGame: boolean) => void;
  setTotalTime: (totalTime: number) => void;
  setTotalScore: (totalScore: number) => void;
  finalActiveTime: number | null;
  setFinalActiveTime: (finalActiveTime: number | null) => void;
  firstPlay: boolean;
  isFetchAndSetRoundsCompleted: boolean;
  showGameSummary: boolean;
  setShowGameSummary: (showGameSummary: boolean) => void;
  setShowCraftwordBestAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showCraftwordBestAlert: boolean;
  isMobile: boolean;
  marginTop: string;
  isSmallScreen: boolean;
  useVirtualKeyBoard: boolean;
  gameDetails: GameDetailType[] | undefined;
  onPlayerStatsDrawerOpen: () => void;
  onHintRequested: () => void;
  onGiveUpRequested: () => void;
  didGiveUp: boolean;
  setDidGiveUp: Dispatch<SetStateAction<boolean>>;
  onShowAlertMessage: () => void;
  competitive: boolean;
};

const Game: React.FC<GameProps> = ({
  wordList,
  gameLength,
  rounds,
  currentRoundIndex,
  currentRound,
  isRoundOver,
  isGameOver,
  addMove,
  advanceRound,
  winningAnimationOver,
  undoMove,
  undoBackwardsMove,
  setPlayedTodaysGame,
  gameDetails,
  setTotalTime,
  setTotalScore,
  finalActiveTime,
  setFinalActiveTime,
  firstPlay,
  isFetchAndSetRoundsCompleted,
  setShowGameSummary,
  showGameSummary,
  setShowCraftwordBestAlert,
  showCraftwordBestAlert,
  clearMoves,
  isMobile,
  marginTop,
  isSmallScreen,
  useVirtualKeyBoard,
  onPlayerStatsDrawerOpen,
  onHintRequested,
  onGiveUpRequested,
  didGiveUp,
  setDidGiveUp,
  onShowAlertMessage,
  competitive,
}: GameProps) => {
  const { archiveDate } = useArchiveDate(); // Use the context for archiveDate
  const [activeTime, setActiveTime] = useState(() => {
    const savedActiveTime = localStorage.getItem("activeTime");
    const savedFinalActiveTime = localStorage.getItem("finalActiveTime");

    if (savedFinalActiveTime !== null) {
      return parseInt(savedFinalActiveTime, 10) * 1000;
    } else if (savedActiveTime !== null) {
      return parseInt(savedActiveTime, 10);
    } else {
      return 0;
    }
  });

  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    localStorage.setItem("activeTime", activeTime.toString());
  }, [activeTime]);

  useEffect(() => {
    let timerId: string | number | NodeJS.Timeout | null | undefined = null;

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (!timerId) {
          timerId = setInterval(() => {
            setActiveTime((prevActiveTime) => prevActiveTime + 1000);
          }, 1000);
        }
      } else {
        if (timerId) {
          clearInterval(timerId);
          timerId = null;
        }
      }
    };
    if (document.visibilityState === "visible" && !timerId) {
      timerId = setInterval(() => {
        setActiveTime((prevActiveTime) => prevActiveTime + 1000);
      }, 1000);
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isGameOver && startTimeRef.current !== null) {
      const timeSpent = Date.now() - startTimeRef.current;
      const newActiveTime = activeTime + timeSpent;
      setActiveTime(newActiveTime);
      setFinalActiveTime(Math.round(newActiveTime / 1000));
      startTimeRef.current = null;
    }
  }, [isGameOver, activeTime]);

  useEffect(() => {
    if (isGameOver && finalActiveTime === null) {
      const finalTimeInSeconds = Math.round(activeTime / 1000);
      setActiveTime(finalTimeInSeconds * 1000);
      setFinalActiveTime(finalTimeInSeconds);
      localStorage.setItem("finalActiveTime", finalTimeInSeconds.toString());
      startTimeRef.current = null;
      const today = formatDateToLocal(new Date());
      let formattedArchiveDate = archiveDate
        ? formatDateToLocal(archiveDate)
        : undefined;
      let dateToAdd;
      if (!formattedArchiveDate || formattedArchiveDate === today) {
        dateToAdd = today;
        setPlayedTodaysGame(true);
      } else {
        dateToAdd = formattedArchiveDate;
        setPlayedTodaysGame(false);
      }
    }
  }, [isGameOver, finalActiveTime]);

  useEffect(() => {
    if (isGameOver) {
      const totalScore = calculateTotalScore(rounds);
      const totalTime = calculateTotalTime(rounds);
      setTotalScore(totalScore);
      setTotalTime(totalTime);
    }
  }, [isGameOver]);

  const allHintsUsed =
    currentRound.bestPossibleLength !== null &&
    currentRound.hintCount === currentRound.bestPossibleLength - 1;

  if (!isFetchAndSetRoundsCompleted) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="25vh"
        mt={isMobile ? { marginTop } : "20vh"}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="grayBrand.700"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <>
      {isRoundOver && !winningAnimationOver ? (
        <>
          {showGameSummary ? (
            <GameSummary
              rounds={rounds}
              activeTime={activeTime}
              finalActiveTime={finalActiveTime}
              gameDetails={gameDetails}
              onPlayerStatsDrawerOpen={onPlayerStatsDrawerOpen}
              onShowAlertMessage={onShowAlertMessage}
            />
          ) : didGiveUp ? (
            <GaveUpRound
              currentRound={currentRound}
              currentRoundIndex={currentRoundIndex}
              advanceRound={advanceRound}
              isGameOver={isGameOver}
              setShowGameSummary={setShowGameSummary}
            />
          ) : (
            <GameRoundResult
              currentRound={currentRound}
              currentRoundIndex={currentRoundIndex}
              advanceRound={advanceRound}
              isGameOver={isGameOver}
              setShowGameSummary={setShowGameSummary}
              setDidGiveUp={setDidGiveUp}
            />
          )}
        </>
      ) : (
        <Flex
          flex="1"
          w="100%"
          direction="column"
          align="center"
          justify="top"
          mt={isMobile ? (isSmallScreen ? "4vh" : "7vh") : "10vh"}
        >
          <GameHeader
            currentRoundIndex={currentRoundIndex}
            gameLength={gameLength}
            currentRound={currentRound}
            marginTop={marginTop}
            isSmallScreen={isSmallScreen}
            hintCount={currentRound.hintCount || 0}
          />
          <Round
            currentRoundIndex={currentRoundIndex}
            rounds={rounds}
            startWord={currentRound.startWord}
            goalWord={currentRound.goalWord}
            moves={currentRound.moves}
            craftwordsBestPathLength={currentRound.bestPossibleLength}
            wordList={wordList}
            addMove={(move, direction) => addMove(move, direction)}
            undoMove={undoMove}
            rareWordsUsed={currentRound.rareWordsUsed}
            isRoundOver={isRoundOver}
            firstPlay={firstPlay}
            setShowCraftwordBestAlert={setShowCraftwordBestAlert}
            showCraftwordBestAlert={showCraftwordBestAlert}
            clearMoves={clearMoves}
            isMobile={isMobile}
            useVirtualKeyBoard={useVirtualKeyBoard}
            onHintRequested={onHintRequested}
            onGiveUpRequested={onGiveUpRequested}
            allHintsUsed={allHintsUsed}
            currentPosition={currentRound.currentPosition}
            undoBackwardsMove={undoBackwardsMove}
            competitive={competitive}
          />
        </Flex>
      )}
    </>
  );
};

export default Game;
