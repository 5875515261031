import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "auto",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h1>Privacy Policy for CraftWord</h1>
      <p>
        <strong>Last Updated:</strong> January 31, 2025
      </p>

      <p>
        Welcome to CraftWord! We value your privacy and are committed to
        protecting your personal information. This Privacy Policy explains how
        we collect, use, and safeguard your data when you use our services.
      </p>

      <h2>1. Information We Collect</h2>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you use our website or
          services, we may collect information such as your name, email address,
          and contact details.
        </li>
        <li>
          <strong>Usage Data:</strong> We may collect information about how you
          access and use our services, including your IP address, browser type,
          and pages visited.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>
          <strong>Provide Services:</strong> To deliver and improve our services
          to you.
        </li>
        <li>
          <strong>Communication:</strong> To send you updates, newsletters, or
          respond to your inquiries.
        </li>
        <li>
          <strong>Security:</strong> To protect against unauthorized access and
          ensure the safety of our services.
        </li>
      </ul>

      <h2>3. Information Sharing</h2>
      <ul>
        <li>
          <strong>No Selling of Information:</strong> CraftWord will never sell,
          rent, or lease your personal information to third parties.
        </li>
        <li>
          <strong>Trusted Partners:</strong> We may share your information with
          trusted partners who assist us in operating our website or providing
          services, provided they agree to keep your information confidential.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your information
          if required by law or to protect our rights.
        </li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We implement appropriate security measures to protect your personal
        information from unauthorized access, alteration, disclosure, or
        destruction.
      </p>

      <h2>5. Your Rights</h2>
      <ul>
        <li>
          <strong>Access:</strong> Request a copy of your personal information.
        </li>
        <li>
          <strong>Update:</strong> Correct or update your personal information.
        </li>
        <li>
          <strong>Delete:</strong> Request the deletion of your personal
          information.
        </li>
      </ul>
      <p>
        To exercise these rights, please contact us using the information below.
      </p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated revision date. We encourage you to
        review this policy periodically.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <p>
        <strong>Email:</strong>{" "}
        <a href="mailto:craftwordfeedback@gmail.com">
          craftwordfeedback@gmail.com
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
