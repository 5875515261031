import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import CraftWordLogoLong from "../components/CraftWordLogoLong";

const MarketingPage = () => {
  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={6} textAlign="center">
        <CraftWordLogoLong width="90%" />
        <Box
          as="video"
          src="/AvailableNow.mp4"
          controls
          playsInline
          autoPlay
          width="100%"
          borderRadius="lg"
          shadow="md"
        />
        <Heading size="lg" color="orangeBrand.500">
          CraftWord - A Daily Word Challenge
        </Heading>
        <Text fontSize="lg" color="grayBrand.700">
          Transform words, unlock puzzles, and challenge your mind with
          CraftWord! Available now.
        </Text>
        <Stack direction={{ base: "column", md: "row" }} spacing={4}>
          <Button
            variant="orangeBrand"
            size="lg"
            as="a"
            href="https://apps.apple.com/us/app/craftword/id6673917345"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download on the App Store
          </Button>
        </Stack>
      </VStack>
    </Container>
  );
};

export default MarketingPage;
