import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { RareWordListProvider } from "./RareWordListContext";
import { DefinitionProvider } from "./DefinitionContext";
import { ArchiveDateProvider } from "./ArchiveDateContext";
import { SolutionsProvider } from "./SolutionsContext";
import GamePage from "./pages/GamePage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ResetPasswordPage from "./components/ResetPasswordPage";
import MarketingPage from "./pages/Marketing";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { theme } from "./theme";
import { Provider, ErrorBoundary } from "@rollbar/react";
import SupportPage from "./pages/Support";

const rollbarConfig = {
  accessToken: "e44aa856dcec496da362867438f9d670",
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
};

export default function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ChakraProvider theme={theme}>
          <ArchiveDateProvider>
            <DefinitionProvider>
              <RareWordListProvider>
                <SolutionsProvider>
                  <Router>
                    <Routes>
                      <Route path="/" element={<GamePage />} />
                      <Route
                        path="/reset-password/:token"
                        element={<ResetPasswordPage />}
                      />
                      <Route path="/privacy" element={<PrivacyPolicy />} />
                      <Route path="/marketing" element={<MarketingPage />} />
                      <Route path="/support" element={<SupportPage />} />
                    </Routes>
                  </Router>
                </SolutionsProvider>
              </RareWordListProvider>
            </DefinitionProvider>
          </ArchiveDateProvider>
        </ChakraProvider>
      </ErrorBoundary>
    </Provider>
  );
}
