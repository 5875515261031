import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useEffect } from "react";

interface NewFeatureModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function NewFeatureModal({
  isOpen,
  onClose,
}: NewFeatureModalProps) {
  const handleClose = () => {
    localStorage.setItem("seenNewFeatureModal", "true");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">New Feature</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody>
          {/* Use the hosted image from the public directory */}
          <Image
            src="/newfeature.jpeg"
            alt="New Feature"
            mb={4}
            borderRadius="md"
            objectFit="cover"
            w="100%"
          />
          <Flex direction="column" padding="4">
            {" "}
            <Text mb={4}>
              You can now enter moves backwards from goal, as well as forwards
              from start.
            </Text>
            <Text mb={4}>
              The Right Arrow button (→) is now for undoing words AHEAD of the
              input.
            </Text>
            <Text mb={4} fontWeight="semibold">
              To enter words, you now must use your device's keyboard.
            </Text>
            <Text mb={4}>Thanks for playing!</Text>
            <Text>-Luke</Text>
          </Flex>
        </ModalBody>
        <ModalFooter mb={4} justifyContent="center">
          <Button colorScheme="orangeBrand" onClick={handleClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
