import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";

interface IOSAnnouncementProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthModalOpen: () => void;
  isMobile: boolean;
  isStandalone: boolean;
  isLoggedIn: boolean;
}

const COOLDOWN_PERIOD = 60 * 60 * 1000; // 1 hour in milliseconds

const IOSAnnouncement: React.FC<IOSAnnouncementProps> = ({
  isOpen,
  onClose,
  isMobile,
  isStandalone,
  isLoggedIn,
}) => {
  const [canShowModal, setCanShowModal] = useState(true);

  useEffect(() => {
    const lastInteractionTime = localStorage.getItem(
      "IOSAnnouncementLastInteraction",
    );
    if (lastInteractionTime) {
      const timeSinceLastInteraction =
        Date.now() - parseInt(lastInteractionTime, 10);
      if (timeSinceLastInteraction < COOLDOWN_PERIOD) {
        setCanShowModal(false);
      }
    }
  }, []);

  const handleInteraction = () => {
    localStorage.setItem(
      "IOSAnnouncementLastInteraction",
      Date.now().toString(),
    );
    setCanShowModal(false);
  };

  const handleDontShowAgainClick = () => {
    localStorage.setItem("dontIOSAnnouncement", "true");
    handleInteraction();
    onClose();
  };

  const hasContent =
    (!isLoggedIn || (isMobile && !isStandalone)) &&
    canShowModal &&
    !localStorage.getItem("dontIOSAnnouncement");

  useEffect(() => {
    if (isOpen && !hasContent) {
      onClose();
    }
  }, [isOpen, hasContent, onClose]);

  if (!hasContent) {
    return null;
  }

  // Use the static message encoded for after March 13
  const mainMessage = "Download CraftWord for iPhone Now!";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">{mainMessage}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="center">
            <Flex align="center" direction="column">
              <a
                href="https://apps.apple.com/app/id6673917345"
                target="_blank"
                rel="noopener noreferrer"
              >
                <video
                  style={{ width: "100%", maxWidth: "300px" }}
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="/AvailableNow.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </a>
            </Flex>
            <VStack align="left" spacing={1}>
              <Text textAlign="left">
                - All New Modes of Play: Infinite Mode, Zen Mode
              </Text>
              <Text textAlign="left">- Play Fully Offline</Text>
              <Text textAlign="left">- Better Hints</Text>
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} mr="auto">
            Close
          </Button>
          <Button
            variant="ghost"
            textColor="grayBrand.500"
            fontSize="xs"
            onClick={handleDontShowAgainClick}
          >
            Don't show this again
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default IOSAnnouncement;
