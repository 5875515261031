import React, { useState, useEffect } from "react";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { LuAward, LuTrophy } from "react-icons/lu";
import { useSolutions } from "../SolutionsContext"; // adjust the path
import { RoundType } from "./types";

type CraftWordBestMessageProps = {
  bestPossibleLength: number | null;
  currentRoundIndex: number;
  rounds: RoundType[];
  competitive: boolean;
};

const CraftWordBestMessage: React.FC<CraftWordBestMessageProps> = ({
  bestPossibleLength,
  currentRoundIndex,
  rounds,
  competitive,
}) => {
  // Get shared solutions data from context
  const { solutionsData } = useSolutions();

  // Compute the best round solution based on your data shape.
  let bestRoundSolution: number | null = null;
  const roundData = solutionsData.find(
    (data) => data.roundIndex === currentRoundIndex,
  );
  if (roundData && rounds[currentRoundIndex]) {
    const goalWord = rounds[currentRoundIndex].goalWord;
    const validSolutions = roundData.solutions.filter((solution) => {
      let words: string[] = [];
      if (typeof solution.solution === "string") {
        words = solution.solution.split(" ");
      } else if (Array.isArray(solution.solution)) {
        words = solution.solution;
      }
      return words[words.length - 1] === goalWord;
    });

    // Helper to compute the number of moves (words)
    const getMoveCount = (sol: any): number => {
      if (typeof sol.solution === "string") {
        return sol.solution.split(" ").length;
      } else if (Array.isArray(sol.solution)) {
        return sol.solution.length;
      }
      return Infinity;
    };

    if (validSolutions.length > 0) {
      bestRoundSolution = validSolutions.reduce((min, sol) => {
        const moves = getMoveCount(sol);
        return moves < min ? moves : min;
      }, getMoveCount(validSolutions[0]));
    }
  }

  // Animation state:
  // phase: 0 = CW's Best only, 1 = Round Best only, 2 = Combined alert
  // opacity: controls fade in/out (animated via CSS transition)
  const [phase, setPhase] = useState<number>(0);
  const [opacity, setOpacity] = useState<number>(0);

  useEffect(() => {
    let t1: ReturnType<typeof setTimeout>;
    let t2: ReturnType<typeof setTimeout>;
    let t3: ReturnType<typeof setTimeout>;
    let t4: ReturnType<typeof setTimeout>;

    if (competitive) {
      // Full sequence when competitive is true:
      setPhase(0);
      setOpacity(1);
      t1 = setTimeout(() => {
        // Fade out Phase 0
        setOpacity(0);
        t2 = setTimeout(() => {
          // Switch to Phase 1: Round Best alert.
          setPhase(1);
          setOpacity(1);
          t3 = setTimeout(() => {
            // Fade out Phase 1
            setOpacity(0);
            t4 = setTimeout(() => {
              // Switch to Phase 2: Combined alert.
              setPhase(2);
              setOpacity(1);
            }, 300); // 0.3s fade-out
          }, 3300); // Hold Phase 1 for 3.3s
        }, 300); // 0.3s fade-out
      }, 3300); // Hold Phase 0 for 3.3s
    } else {
      // Simplified sequence when competitive is false:
      setPhase(0);
      setOpacity(1);
      t1 = setTimeout(() => {
        // Fade out Phase 0
        setOpacity(0);
        t2 = setTimeout(() => {
          // Switch to Phase 1: Minimal display.
          setPhase(1);
          setOpacity(1);
        }, 300);
      }, 3300);
    }

    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
      if (competitive) {
        clearTimeout(t3);
        clearTimeout(t4);
      }
    };
  }, [currentRoundIndex, bestPossibleLength, bestRoundSolution, competitive]);

  return (
    <Flex
      alignItems="center"
      fontSize="sm"
      color="grayBrand.600"
      style={{
        transition: "opacity 0.3s ease-in-out",
        opacity: opacity,
      }}
    >
      {competitive ? (
        <>
          {phase === 0 && (
            <>
              <Icon as={LuAward} mr={1} fontSize="1em" />
              <Text>CraftWord's Best: {bestPossibleLength} moves</Text>
            </>
          )}
          {phase === 1 && (
            <>
              <Icon as={LuTrophy} mr={1} fontSize="1em" />
              <Text>
                {bestRoundSolution !== null
                  ? `Best Score Today: ${bestRoundSolution} moves`
                  : "Best Score Today: -"}
              </Text>
            </>
          )}
          {phase === 2 && (
            <>
              <Icon as={LuAward} mr={1} fontSize="1em" />
              <Text>: {bestPossibleLength}</Text>
              <Icon as={LuTrophy} ml={3} mr={1} fontSize="1em" />
              <Text>
                {bestRoundSolution !== null ? `: ${bestRoundSolution}` : ": -"}
              </Text>
            </>
          )}
        </>
      ) : (
        <>
          {phase === 0 && (
            <>
              <Icon as={LuAward} mr={1} fontSize="1em" />
              <Text>CraftWord's Best: {bestPossibleLength} moves</Text>
            </>
          )}
          {phase === 1 && (
            <>
              <Icon as={LuAward} mr={1} fontSize="1em" />
              <Text>: {bestPossibleLength}</Text>
            </>
          )}
        </>
      )}
    </Flex>
  );
};

export default CraftWordBestMessage;
